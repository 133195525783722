@tailwind base;
@tailwind components;
@tailwind utilities;


.font-name-kanit {
    font-family: 'Kanit', sans-serif;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
input[type="search"]::-ms-clear {
    display: none;
    height: 0;
    width : 0;
}

input[type="search"]::-ms-reveal {
    display: none;
    height: 0;
    width : 0;
}

.search-box{
    @apply relative;
    @apply bg-white border shadow-sm border-gray-300 placeholder-gray-300
     disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none
     focus:border-gray-500  block w-[98%] h-[78%] rounded-md sm:text-sm;
}
.search-input{
    padding-top: 2%;
    @apply mx-11 focus:border-gray-500 block w-[90%] h-[96%] text-base sm:text-lg focus:outline-none;
     
}

.search-input-have-button{
    padding-top: 2%;
    padding-bottom: 2%;
    @apply ml-[2.5rem] md:ml-[4rem] focus:border-gray-500  block w-[77%] md:w-[80%] h-[96%] text-base sm:text-lg focus:outline-none;
     
}
.icon-search-box{
    @apply w-10 md:w-[4rem] h-[100%] text-center absolute top-1/2 transform -translate-y-1/2 right-0;
    @apply opacity-100 visible;
    @apply text-gray-500;
}

.icon-loading-box{
    @apply w-10 md:w-[4rem] h-[100%] text-center absolute top-1/2 transform -translate-y-1/2 right-0;
    @apply opacity-100 visible;
    @apply text-gray-500;
}

.icon-burger-box{
    @apply w-10 md:w-[4rem] h-[100%] text-center absolute top-1/2 transform -translate-y-1/2 left-0;
    @apply opacity-100 visible;
    @apply text-gray-500;
}

.icon-microphone-box{
    @apply block w-8 h-[100%] text-2xl text-center absolute top-1/2 transform -translate-y-1/2 right-0;
    @apply opacity-100 visible;
    @apply text-gray-500;
}
.icon-microphone-box .visit-none{
    display: none;
}
@media screen and (max-height: 750px) {
    .search-input{
        padding-top: 1%;
        padding-bottom: 1%;
    }
}