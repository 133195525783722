@tailwind base;
@tailwind components;
@tailwind utilities;

.button-gps {
    @apply bg-slate-50 text-stone-950 font-bold shadow-2xl rounded-full;

    width: calc(60px + 0.5vw);
    height: calc(60px + 0.5vw);
}

.button-layer-map {
    @apply bg-slate-50 text-stone-950 font-bold shadow-2xl rounded-full;

    width: calc(60px + 0.5vw);
    height: calc(60px + 0.5vw);
}

@media screen and (min-width: 400px) {
    .button-gps {
        width: calc(60px + 1vw + 0.5vh);
        height: calc(60px + 1vw + 0.5vh);
    }

    .button-layer-map {
        width: calc(60px + 1vw + 0.5vh);
        height: calc(60px + 1vw + 0.5vh);
    }
}

@media screen and (min-width: 760px) and (max-width: 1024px) and (orientation: portrait) {
    .button-gps {
        width: calc(72px + 1vw + 0.5vh);
        height: calc(72px + 1vw + 0.5vh);
    }

    .button-layer-map {
        width: calc(72px + 1vw + 0.5vh);
        height: calc(72px + 1vw + 0.5vh);
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .button-gps {
        width: calc(48px + 1vw + 0.5vh);
        height: calc(48px + 1vw + 0.5vh);
    }

    .button-layer-map {
        width: calc(48px + 1vw + 0.5vh);
        height: calc(48px + 1vw + 0.5vh);
    }
}