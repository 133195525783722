.modal {
    transform: translateY(-20%);
    opacity: 1;
  }
  
.modal.open {
    transition: transform 0.3s ease-out;
    transform: translateY(0%);
}

.modal.close {
    transition: transform 0.4s ease-in-out;
    transform: translateY(+30%);
}