@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar {
    @apply absolute top-0 left-0 h-[100dvh] w-[100%] bg-white ;
    z-index: 1200;
    transition: 0.1s;
}

.bg-sidebar {
    @apply absolute top-0 left-0 h-[100dvh] w-[100%] bg-black bg-opacity-70 ;
    z-index: 1100;
    transition: 0.5s;
}