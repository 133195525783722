
.suggestlist{
    .remove-button{
        @apply hidden
    }
}
.suggestlist:hover{

    .remove-button{
        display: block;
    }
}